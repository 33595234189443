import React, {PureComponent} from "react";
import {Field, formValueSelector} from "redux-form";
import {injectIntl} from "react-intl";
import PropTypes from "prop-types";

import {Col, Grid, Section} from "../../../../components";
import {constants, messages} from "../../../../constants";
import {validationBuilder, validations} from "../..";
import ImageCapture from "./index";
import * as selectors from "../../../../selectors";
import {connect} from "react-redux";
import { ImageConverterFactory } from '../../../../util/imageConverter';


let rules = {};

class CaptureImageWrapper extends PureComponent {
  imageValidate = [];
  state = {imageFile: []};

  constructor(props) {
    super(props);
    // for field level validations
    // TODO: find a better way to instantiate props when the component mounts
    Object.keys(validations).forEach(key => {
      rules[key] = spec => value => validationBuilder(props, validations[key], spec, true)(value);
    });

    let requiredMessage = messages.field.driverLicenseFront;

    if (props.isSelfie) {
      requiredMessage = messages.field.selfie;
    } else if (props.isBack) {
      requiredMessage = messages.field.driverLicenseBackImage;
    }

    this.imageValidate = [
      rules.required(props.intl.formatMessage(requiredMessage))];
  }

  // function to get the selected field value
  getValue = (name, overrideSection = undefined) => {
    const {state, form, section} = this.props;
    const selector = formValueSelector(form);
    if (overrideSection) {
      return selector(state, `${overrideSection}.${name}`);
    }
    if (section) {
      return selector(state, `${section}.${name}`);
    }
    return selector(state, name);
  };

  onValueChange = async (filenew, onChange) => {
    const { dispatch } = this.props;
    if (filenew) {
      dispatch({ type: constants.SPINNER_ON });
      const converter = new ImageConverterFactory().getConverter(filenew);
      if (converter) {
        filenew = await converter.convert(filenew);
      }
      this.setState({ imageFile: filenew });
      const localImageUrl = URL.createObjectURL(filenew);
      onChange(localImageUrl);
      dispatch({ type: constants.SPINNER_OFF });
    }
  }

  getName = () => {
    const {isBack, isSelfie} = this.props;
    let namePostfix = isBack ? "Back" : "Front";
    namePostfix = isSelfie ? "Selfie" : namePostfix;
    return "image" + namePostfix;
  }

  render() {

    return (
      <Section>
        <Grid>
          <Col>
            <Field
              type="file"
              name={this.getName()}
              required={true}
              validate={this.imageValidate}
              component={ImageCapture}
              onValueChange={this.onValueChange}
              getValue={this.getValue}
              getName={this.getName}
              {...this.props}
            />
          </Col>
        </Grid>
      </Section>
    );
  }
}

CaptureImageWrapper
  .propTypes = {
  intl: PropTypes.object.isRequired,
  form: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  section: PropTypes.string,
  disabled: PropTypes.bool,
  orientation: PropTypes.string.isRequired,
  upperImageCaption: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  handleValidate: PropTypes.func.isRequired,
  isSelfie: PropTypes.bool.isRequired,
  isBack: PropTypes.bool.isRequired,
  driverLicenceFront: PropTypes.object,
  driversLicenseBack: PropTypes.object,
  selfie: PropTypes.object,
  actions: PropTypes.objectOf(PropTypes.func),
  reset: PropTypes.func
};


const mapStateToProps = state => ({
  state,
  driverLicenceFront: selectors.getDriversLicenseFront(state),
  driversLicenseBack: selectors.getDriversLicenseBack(state),
  selfie: selectors.getSelfie(state)
});

export default connect(mapStateToProps) (injectIntl(CaptureImageWrapper));

