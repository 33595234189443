import { defineMessages } from "react-intl";

const namespace = `affiliateProgram`;
const affiliateProgram = defineMessages({
  lang: {
    id: `${namespace}.lang`,
    description: "Language Field",
    defaultMessage: "Language"
  },
  message: {
    id: `${namespace}.message`,
    description: "Message Field",
    defaultMessage: "Tell us why you'd love to be an Evo Affiliate"
  },
  title: {
    id: `${namespace}.title`,
    description: "Page Title",
    defaultMessage: "Apply to be an Evo Affiliate"
  },
  description: {
    id: `${namespace}.description`,
    description: "Page Description",
    defaultMessage: `Thanks for your interest in becoming an Evo Affiliate, we’re thrilled you’re here! Enter your details below and we’ll email you with next steps.`
  },
  successMessage: {
    id: `${namespace}.successMessage`,
    description: "Success Message",
    defaultMessage: `Success!`
  },
  successMessageDescription: {
    id: `${namespace}.successMessageDescription`,
    description: "Success Message Description",
    defaultMessage: `Keep an eye on your inbox for next steps.`
  },
  agreement: {
    id: `${namespace}.agreement`,
    description: "Agreement Checkbox",
    defaultMessage: "I confirm I have read and accepted the {terms}."
  },
});

export default affiliateProgram;
