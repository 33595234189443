import { constants } from "../constants";


const EMAIL_FORM_KEY = {
    PAYLOAD: "payload",
    TYPE: "type"
}


export class EmailFormData {
    constructor(payload, type) {
        if (!payload) {
        throw new Error('Payload is required');
        }
        if (!type) {
        throw new Error('Email type is required');
        }

        // Check if the type is valid
        if (!Object.values(constants.EMAIL_TYPE).includes(type)) {
            throw new Error(`Invalid email type. Must be one of: ${Object.values(constants.EMAIL_TYPE).join(', ')}`);
        }

        this.formData = new FormData();
        this.formData.append(EMAIL_FORM_KEY.PAYLOAD, JSON.stringify(payload));
        this.formData.append(EMAIL_FORM_KEY.TYPE, type);
    }

    getFormData() {
        return this.formData;
    }
}