import heic2any from 'heic2any';


// Abstract base class for image converters
class ImageConverter {
    async convert(file) {
        throw new Error('convert() method must be implemented');
    }

    isApplicable(file) {
        throw new Error('isApplicable() method must be implemented');
    }
}

// Converters
class HeicConverter extends ImageConverter {
    isApplicable(file) {
        return file.name.match(/\.(heic|heif)$/i) !== null;
    }

    async convert(file) {
        try {
            const blob = await heic2any({
                blob: file,
                toType: 'image/jpeg'
            });
            
            return new File(
                [blob], 
                file.name.replace(/\.(heic|heif)$/i, '.jpg'), 
                { type: 'image/jpeg' }
            );
        } catch (error) {
            console.error('HEIC conversion failed:', error);
            return file;
        }
    }
}

class WebpConverter extends ImageConverter {
    isApplicable(file) {
        return file.name.match(/\.(webp)$/i) !== null;
    }

    async convertWebPtoJPG(webpBlob) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                const canvas = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext("2d");
                if (!ctx) {
                    reject(new Error("Could not create canvas context"));
                    return;
                }
                ctx.drawImage(img, 0, 0);
                canvas.toBlob((jpgBlob) => {
                    if (jpgBlob) {
                        resolve(jpgBlob);
                    } else {
                        reject(new Error("Failed to convert WebP to JPG"));
                    }
                }, "image/jpeg", 1);
            };
            img.onerror = reject;
            img.src = URL.createObjectURL(webpBlob);
        });
    }

    async convert(file) {
        try {
            const blob = await this.convertWebPtoJPG(file);
            
            return new File(
                [blob], 
                file.name.replace(/\.(webp)$/i, '.jpg'), 
                { type: 'image/jpeg' }
            );
        } catch (error) {
            console.error('WEBP conversion failed:', error);
            return file;
        }
    }
}

// Factory class
class ImageConverterFactory {
    constructor() {
        this.converters = [
            new HeicConverter(),
            new WebpConverter()
            // Add more converters here as needed
        ];
    }

    getConverter(file) {
        const converter = this.converters.find(converter => converter.isApplicable(file));
        return converter || null;
    }

    async convertImage(file) {
        const converter = this.getConverter(file);
        if (converter) {
            return await converter.convert(file);
        }
        return file;
    }
}

export { ImageConverterFactory };
