import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Phone from "react-phone-number-input";
import cn from "classnames";

import "react-phone-number-input/style.css";
import "./input.scss";

import { Tooltip, Txt } from "../../elements";

class Input extends PureComponent {
  static displayName = "Input";

  state = { showPassword: false };

  componentDidMount() {
    const { input } = this.props;
    if (input && input.value) {
      input.onBlur(input.value);
    }
  }

  render() {
    const {
      className,
      type,
      label,
      disabled,
      required,
      tooltip,
      input,
      meta,
      dirtylook,
      disableErrorIcon,
      preventSubmitPressEnter,
      alignLeft,
      hideErrorMessage,
      maxLength,
      allowPaste
    } = this.props;
    const { showPassword } = this.state;

    // redux form props
    const { onChange, value, name } = input;
    const { touched, valid, warning, invalid, error, active, asyncValidating } = meta;
    const labelText = `${label}${required ? " *" : ""}`;
    const isPassword = type === "password";
    const inputtype = isPassword ? (showPassword ? "text" : type) : type;

    const inputCSS = cn("input", className, {
      "is-valid": touched && valid,
      "is-invalid": touched && invalid,
      "is-warning": touched && warning,
      "is-focused": active,
      "has-icon": !!tooltip
    });
    const passwordIconCSS = cn("fa", {
      "fa-eye-slash": showPassword,
      "fa-eye": !showPassword
    });

    const renderField =
      type === "tel" ? (
        <Phone
          international={true}
          countryCallingCodeEditable={false}
          withCountryCallingCode={true}
          countryOptionsOrder={["CA", "US","|", "..."]}
          {...input}
          name={name}
          id={name}
          placeholder={dirtylook ? "" : labelText}
          value={value}
          className={value || dirtylook ? "" : ""}
          disabled={disabled}
          required={required}
          onChange={value => onChange(value ? value : "")}
          defaultCountry="CA"
          maxLength={16}
          onPaste={e=> {
            if(!allowPaste)
            {
              e.preventDefault();
            }
          }}
        />
      ) : (type === "number" ?

        <input
        {...input}
        maxLength={maxLength}
        onChange={this.handleChange}
        disabled={disabled}
        type="text"
        placeholder={dirtylook ? "" : labelText}
        className={!!value ? "" : "placeholder-shown"}
        id={name}
        required={required}
        onKeyPress={e => {
          if (preventSubmitPressEnter && e.key === "Enter") {
            e.preventDefault();
          }
          if(! (e.key.match(/^[\d]+$/))){
            e.preventDefault();
          }
        }}
        onPaste={e=> {
          if(!allowPaste)
          {
            e.preventDefault();
          }
        }}
      />:(
        <input
          {...input}
          maxLength={maxLength}
          onChange={this.handleChange}
          disabled={disabled}
          type={inputtype}
          placeholder={dirtylook ? "" : labelText}
          className={!!value ? "" : "placeholder-shown"}
          id={name}
          required={required}
          onKeyPress={e => {
            if (preventSubmitPressEnter && e.key === "Enter") {
              e.preventDefault();
            }
          }}
          onPaste={e=> {
            if(!allowPaste)
            {
              e.preventDefault();
            }
          }}
        />
      ));

    let errorTxtTheme = ["small"];
    if (!disableErrorIcon) {
      errorTxtTheme.push("error");
    }
    if (alignLeft) {
      errorTxtTheme.push("left");
    }
    return (
      <fieldset className={inputCSS} role="group">
        <div className={asyncValidating? 'async-validating input-field': 'input-field'}>
          {renderField}
          <label htmlFor={name} className={type==="tel"?"phone-label":null}>{labelText}</label>
          {isPassword && <i className={passwordIconCSS} onClick={this.handleClickEyeIcon} />}
          {tooltip && <Tooltip>{tooltip}</Tooltip>}
        </div>
        {!hideErrorMessage && touched && error && (
          <Txt theme={errorTxtTheme}>
            <small>{error}</small>
          </Txt>
        )}
      </fieldset>
    );
  }

  handleChange = value => {
    const { input, onPostChange } = this.props;
    input.onChange(value);
    if (onPostChange) {
      onPostChange(value);
    }
  };

  handleClickEyeIcon = () => {
    this.setState(({ showPassword }) => ({ showPassword: !showPassword }));
  };
}

Input.defaultProps = {
  className: "",
  type: "",
  name: "",
  label: "",
  disabled: false,
  disableErrorIcon: false,
  required: false,
  tooltip: "",
  state: {},
  dirtylook: false,
  preventSubmitPressEnter: false,
  onPostChange: () => {},
  alignLeft: false,
  hideErrorMessage: false,
  maxLength: "254",
  allowPaste: true
};

Input.propTypes = {
  className: PropTypes.string,
  maxLength: PropTypes.string,
  type: PropTypes.string,
  alignLeft: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  disableErrorIcon: PropTypes.bool,
  required: PropTypes.bool,
  tooltip: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  state: PropTypes.object,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  dirtylook: PropTypes.bool,
  preventSubmitPressEnter: PropTypes.bool,
  onPostChange: PropTypes.func,
  hideErrorMessage: PropTypes.bool,
  allowPaste: PropTypes.bool
};

export default Input;
