import React from "react";
import { PagesContainer } from "../../containers";
import { AffiliateProgramScreen } from "../../screens";

const AffiliateProgram = (props) => {
  return (
    <PagesContainer {...props}>
      <AffiliateProgramScreen {...props} />
    </PagesContainer>
  );
};

AffiliateProgram.defaultProps = {};

export default AffiliateProgram;
