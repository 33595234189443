import React, { PureComponent } from "react";

import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { compose } from "redux";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";

import { Checkbox, Input, Grid, Col, CheckMark, Section } from "../../components";
import { messages, constants } from "../../constants";

import { validationBuilder, validations } from "../../containers/form";
import { Txt, Button, Title } from "../../elements";

import { validateEmail } from "../../util";

import affiliateProgramImage from "../../assets/img/affiliate-program.jpg";
import { EmailFormData } from "../../util/emailFormData";


const settings = {
  form: constants.FORM.affiliateProgramForm,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  touchOnChange: true,
};
class AffiliateProgram extends PureComponent {
  rulePhone = [];
  state = {
    clientSideValidationError: null,
    resetSuccess: false
  };

  constructor(props) {
    super(props);

    const rules = Object.fromEntries(
      Object.entries(validations).map(([key, validation]) => [
        key,
        (spec, overrideErrorMessage=false) => (value) => {
          return validationBuilder(props, validation, spec, overrideErrorMessage)(value)
        }
      ])
    );
    const { intl } = props;
    this.ruleFirstName = [
      rules.required(intl.formatMessage(messages.personal.firstName)),
      rules.names(intl.formatMessage(messages.error.firstNameFormat), true)
    ];
    this.ruleLastName = [
      rules.required(intl.formatMessage(messages.personal.lastName)),
      rules.names(intl.formatMessage(messages.error.lastNameFormat), true)
    ];
    this.rulesEmail = [
      rules.required(intl.formatMessage(messages.field.EmailAddress)),
      rules.email()
    ];
    this.rulePhone = [rules.phone()];
    this.ruleAgreement = [rules.required(intl.formatMessage(messages.error.acceptTermsAndConditions), true)];
  }

  sendEmail = async (payload) => {
    const { intl } = this.props;
    if (!validateEmail(payload.email)) {
      this.setState({ clientSideValidationError: intl.formatMessage(messages.error.email) });
      return null;
    }
    const emailFormData = new EmailFormData(payload, constants.EMAIL_TYPE.AFFILIATE)

    try {
      const resp = await fetch(constants.ENDPOINTS.sendEmail, {
        method: "POST",
        body: emailFormData.getFormData()
      });

      if (resp.status !== 200) {
        this.setState({
          clientSideValidationError: "Something went wrong!",
          resetSuccess: false
        });
      } else {
        this.setState({
          clientSideValidationError: null,
          resetSuccess: true
        });
      }
      return resp;
    } catch (error) {
      this.setState({
        clientSideValidationError: error.message,
        resetSuccess: false
      });
      //   throw error;
    }
  };

  renderSuccessfulScreen = () => {
    const { intl } = this.props;

    return (
      <div className="reset-code">
        <Grid theme={["center"]}>
          <Col size="1-2" min="sm">
            <CheckMark />
            <Title
              priority={1}
              type={["strong"]}
              style={{
                padding: "50px"
              }}
              className="h1 reset-code__title blue"
            >
              {intl.formatMessage(messages.affiliateProgram.successMessage)}
            </Title>
            <Title priority={5} className="h5 reset-code__title">
              {intl.formatMessage(messages.affiliateProgram.successMessageDescription)}
            </Title>
          </Col>
        </Grid>
      </div>
    );
  };

  render() {
    const { submitting, invalid, pristine, intl, handleSubmit } = this.props;
    let checkboxMessage = intl.formatMessage(messages.affiliateProgram.agreement, {
      terms: (
        <a href="https://evo.ca/affiliatetc" target="_blank" rel="noreferrer">
          Terms & Conditions
        </a>
      )
    });

    if (this.state.clientSideValidationError) {
      return this.state.clientSideValidationError;
    }

    if (this.state.resetSuccess) {
      return this.renderSuccessfulScreen();
    }
    return (
      <div className="outerForm">
        <Title priority={2}>
          {intl.formatMessage(messages.affiliateProgram.title)}
        </Title>
        <img
          style={{ width: "100%", height: "400px", objectFit: "cover" }}
          src={affiliateProgramImage}
          alt="group of people taking a selfie"
        />
        <div style={{ padding: "30px 0" }}>
          <form
            model={constants.FORM.affiliateProgramForm}
            onSubmit={handleSubmit(this.sendEmail)}
            noValidate
          >
            <Txt theme={[`middle`, `large`]}>
              {intl.formatMessage(messages.affiliateProgram.description)}
            </Txt>
            <Section size="2-3" min="md">
              <Grid>
                <Col size="2-3" min="sm">
                  <Field
                    type="text"
                    name="firstName"
                    label={intl.formatMessage(messages.personal.firstName)}
                    required={true}
                    component={Input}
                    validate={this.ruleFirstName}
                    props={{ maxLength: "50" }}
                  />
                </Col>
                <Col size="2-3" min="sm">
                  <Field
                    type="text"
                    name="lastName"
                    label={intl.formatMessage(messages.personal.lastName)}
                    required={true}
                    component={Input}
                    validate={this.ruleLastName}
                    props={{ maxLength: "50" }}
                  />
                </Col>

                <Col size="2-3" min="sm">
                  <Field
                    type="email"
                    name="email"
                    label={intl.formatMessage(messages.field.EmailAddress)}
                    required={true}
                    validate={this.rulesEmail}
                    component={Input}
                    props={{ maxLength: "50" }}
                  />
                </Col>
                <Col size="2-3" min="sm">
                  <Field
                    type="tel"
                    name="phoneNumber"
                    label={intl.formatMessage(messages.personal.tel)}
                    component={Input}
                    props={{ maxLength: "20" }}
                  />
                </Col>
                <Col size="1-1" min="sm">
                  <Field
                    type="text"
                    name="message"
                    label={intl.formatMessage(messages.affiliateProgram.message)}
                    component={Input}
                    props={{ maxLength: "160" }}
                  />
                </Col>
                <Col size="2-4" min="sm">
                  <Field
                      type="checkbox"
                      component={Checkbox}
                      name="acceptTermsAndConditions"
                      required={true}
                      validate={this.ruleAgreement}
                      label={checkboxMessage}
                  />
                </Col>
              </Grid>
            </Section>
            <br />
            <Button
              loading={submitting}
              type="submit"
              theme={["cta"]}
              disabled={invalid || submitting || pristine}
            >
              {intl.formatMessage(messages.button.submit)}
            </Button>
          </form>
        </div>
      </div>
    );
  }
}

AffiliateProgram.propTypes = {
  intl: PropTypes.object.isRequired,
  submitting: PropTypes.bool
};

AffiliateProgram.defaultProps = {
  button: "",
  submitting: false
};

const mapStateToProps = (state) => ({
  state
});

const enhancer = compose(injectIntl, connect(mapStateToProps), reduxForm(settings));

export default enhancer(AffiliateProgram);
