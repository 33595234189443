import React, { PureComponent } from "react";

import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { compose } from "redux";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";

import { Input, Grid, Col, CheckMark, Dropdown } from "../../components";
import { messages, constants } from "../../constants";

import { validationBuilder, validations } from "../../containers/form";
import { Txt, Button, Title } from "../../elements";

import { convertToSentenceCase, validateEmail } from "../../util";

import evoB2B from "../../assets/img/evo-b2b.jpg";
import "./index.scss";
import { EmailFormData } from "../../util/emailFormData";

let rules = {};
const settings = {
  form: constants.FORM.contactUsBusinessForm, // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true // <------ unregister fields on unmount
};
class ContactUsBusiness extends PureComponent {
  rulePhone = [];
  state = {
    clientSideValidationError: null,
    resetSuccess: false
  };

  constructor(props) {
    super(props);
    // for field level validations
    // TODO: find a better way to instantiate props when the component mounts
    Object.keys(validations).forEach((key) => {
      rules[key] = (spec) => (value) => validationBuilder(props, validations[key], spec)(value);
    });
    const { intl } = props;
    this.ruleCompanyName = [
      rules.required(intl.formatMessage(messages.business.companyName)),
    ];
    this.ruleFirstName = [
      rules.required(intl.formatMessage(messages.personal.firstName)),
      rules.names(intl.formatMessage(messages.personal.firstName))
    ];
    this.ruleLastName = [
      rules.required(intl.formatMessage(messages.personal.lastName)),
      rules.names(intl.formatMessage(messages.personal.lastName))
    ];
    this.rulesEmail = [
      rules.required(intl.formatMessage(messages.field.EmailAddress)),
      rules.email()
    ];
    this.rulePhone = [rules.phone()];
  }

  sendEmail = async (payload) => {
    const { intl } = this.props;
    if (!validateEmail(payload.email)) {
      this.setState({ clientSideValidationError: intl.formatMessage(messages.error.email) });
      return null;
    }
    const emailFormData = new EmailFormData(payload, constants.EMAIL_TYPE.BUSINESS)
    
    try {
      const resp = await fetch(constants.ENDPOINTS.sendEmail, {
        method: "POST",
        body: emailFormData.getFormData()
      });

      if (resp.status !== 200) {
        this.setState({
          clientSideValidationError: "Something went wrong!",
          resetSuccess: false
        });
      } else {
        this.setState({
          clientSideValidationError: null,
          resetSuccess: true
        });
      }
      return resp;
    } catch (error) {
      this.setState({
        clientSideValidationError: error.message,
        resetSuccess: false
      });
      //   throw error;
    }
  };

  renderSuccessfulScreen = () => {
    const { intl } = this.props;

    return (
      <div className="reset-code">
        <Grid theme={["center"]}>
          <Col size="1-2" min="sm">
            <CheckMark />
            <Title
              priority={1}
              type={["strong"]}
              style={{
                padding: "50px"
              }}
              className="h1 reset-code__title blue"
            >
              {intl.formatMessage(messages.business.successMessage)}
            </Title>
            <Title priority={5} className="h5 reset-code__title">
              {intl.formatMessage(messages.business.successMessageDescription)}
            </Title>

            {/* <Button className="reset-code__button" type="submit" theme={["large"]}>
                {<span>{intl.formatMessage(messages.button.goToLogin)}</span>}
              </Button> */}
          </Col>
        </Grid>
      </div>
    );
  };

  render() {
    const { submitting, invalid, pristine, intl, handleSubmit } = this.props;

    if (this.state.clientSideValidationError) {
      return this.state.clientSideValidationError;
    }

    if (this.state.resetSuccess) {
      return this.renderSuccessfulScreen();
    }
    return (
      <div className="outerForm">
        <img
          style={{ width: "100%", height: "400px", objectFit: "cover" }}
          src={evoB2B}
          alt="EvoApp"
        />
        <div style={{ padding: "30px 0" }}>
          <form
            model={constants.FORM.contactUsBusinessForm}
            onSubmit={handleSubmit(this.sendEmail)}
            noValidate
          >
            <Title priority={2} type={["strong-no-cap"]}>
              {convertToSentenceCase(intl.formatMessage(messages.business.title))}
            </Title>
            <Txt theme={[`middle`, `large`]}>
              {intl.formatMessage(messages.business.description)}
            </Txt>
            <Grid
              className={"business-form"}
              style={{ padding: "0", margin: "0", gridGap: "20px" }}
            >
              <Col size="2-3" min="sm">
                <Field
                  type="text"
                  name="companyName"
                  label={intl.formatMessage(messages.business.companyName)}
                  required={true}
                  component={Input}
                  validate={this.ruleCompanyName}
                  props={{ maxLength: "50" }}
                />
              </Col>
              <Col size="2-3" min="sm">
                <Field
                  type="text"
                  name="industry"
                  label={intl.formatMessage(messages.business.industry)}
                  component={Input}
                  props={{ maxLength: "50" }}
                />
              </Col>
              <Col size="2-3" min="sm">
                <Field
                  name="numOfEmployees"
                  label={intl.formatMessage(messages.business.numOfEmployees)}
                  options={[
                    { label: "1-10", value: "1-10" },
                    { label: "11-50", value: "11-50" },
                    { label: "51-250", value: "51-250" },
                    { label: "251-500", value: "251-500" },
                    { label: " 500+", value: " 500+" }
                  ]}
                  component={Dropdown}
                />
              </Col>
              <Col size="1-2" min="sm">
                <Field
                  type="text"
                  name="firstName"
                  label={intl.formatMessage(messages.personal.firstName)}
                  required={true}
                  component={Input}
                  validate={this.ruleFirstName}
                  props={{ maxLength: "50" }}
                />
              </Col>
              <Col size="1-2" min="sm">
                <Field
                  type="text"
                  name="lastName"
                  label={intl.formatMessage(messages.personal.lastName)}
                  required={true}
                  component={Input}
                  validate={this.ruleLastName}
                  props={{ maxLength: "50" }}
                />
              </Col>

              <Col size="2-4" min="sm">
                <Field
                  type="email"
                  name="email"
                  label={intl.formatMessage(messages.field.EmailAddress)}
                  required={true}
                  validate={this.rulesEmail}
                  component={Input}
                  props={{ maxLength: "50" }}
                />
              </Col>
              <Col size="2-3" min="sm">
                <Field
                  type="tel"
                  name="phoneNumber"
                  label={intl.formatMessage(messages.personal.tel)}
                  component={Input}
                  props={{ maxLength: "20" }}
                />
              </Col>
              <Col size="1-1" min="sm">
                <Field
                  type="text"
                  name="message"
                  label={intl.formatMessage(messages.business.message)}
                  component={Input}
                  props={{ maxLength: "160" }}
                />
              </Col>
            </Grid>
            <br />
            <Button
              loading={submitting}
              type="submit"
              theme={["cta"]}
              disabled={invalid || submitting || pristine}
            >
              {intl.formatMessage(messages.button.submit)}
            </Button>
          </form>
        </div>
      </div>
    );
  }
}

ContactUsBusiness.propTypes = {
  intl: PropTypes.object.isRequired,
  submitting: PropTypes.bool
};

ContactUsBusiness.defaultProps = {
  button: "",
  submitting: false
};

const mapStateToProps = (state) => ({
  state
});

const enhancer = compose(injectIntl, connect(mapStateToProps), reduxForm(settings));

export default enhancer(ContactUsBusiness);
